import styled from "styled-components";

const sideBarStyle = styled.div`
  width: 100%;
  max-width: 400px;
  height: 780px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  position: absolute;
  z-index: 3;
  background: white;
  top: 15px;
  right: 22px;
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  .wrapper-first {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #3f4b66;
    .icon-close {
      width: 45px;
      font-size: 22px;
      cursor: pointer;
      &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.01);
      }
    }
    svg {
      margin-left: 10px;
    }
  }
  /* Tab List  */
  li {
    padding: 0px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid #3f4b66;
    color: #000;
    margin-bottom: 0;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #000;
      color: #fff;
      path {
        fill: #fff;
      }
    }
    a.wrapper-link {
      padding: 20px;
      width: 100%;
      &:hover {
        color: #fff;
      }
    }
  }
  .tab {
    padding: 20px;
    display: flex;
  }
  @media only screen and (max-width: 598px) {
    max-width: 95%;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

export default sideBarStyle;
