const ga = {
  getClientId: () => {
    console.info("getClientId called");
    try {
      const trackers = window.ga.getAll();
      let i;
      let len;
      for (i = 0, len = trackers.length; i < len; i += 1) {
        if (trackers[i].get("trackingId") === "UA-2728311-36") {
          return trackers[i].get("clientId");
        }
      }
    } catch (e) {
      return false;
    }
  },
  trackEvent: obj => {
    if (typeof window.ga === "function") {
      try {
        window.ga("bcovAnalytics.send", "event", obj);
      } catch (error) {
        console.error("error with bcovAnalytics", error);
        try {
          window.ga("send", "event", obj);
        } catch (e) {
          console.error(
            "I cant do anything right. No general window.ga send",
            e
          );
        }
      }
    } else {
      console.error("event not registered");
    }
  },
};
export default ga;
