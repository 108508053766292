import React from "react";
import ImageBlockStyles from "./ImageBlockStyles";

import ContentfulImage from "@/src/lib/contentful-image";

import Remark from "@/src/components/Global_Components/Remark";

const ImageBlock = ({ itemDefaultDescription, imageBlock }) => {
  return (
    <ImageBlockStyles>
      {/* Image  */}
      {imageBlock && (
        <ContentfulImage
          alt={imageBlock?.fields?.title || ""}
          width={imageBlock?.fields?.file?.details.image.width || 1000}
          height={imageBlock?.fields?.file?.details.image.height || 1000}
          quality={100}
          priority={true}
          src={imageBlock?.fields?.file?.url}
        />
      )}
      {/* Description */}
      <Remark className="Text-Image-Block" string={itemDefaultDescription} />
    </ImageBlockStyles>
  );
};

export default ImageBlock;
