import React from "react";

import SearchStyle from "./SearchStyles";
import SearchIcon from "./icon";

const Search = ({ lang, translations }) => {
  const nameId = Math.random().toString(36).substring(7);
  return (
    <SearchStyle>
      {/* Form */}
      <form action={`/${lang}/search`} method="get">
        <label htmlFor={`search-input-${nameId}`}>
          <input
            id={`search-input-${nameId}`}
            name="query"
            type="text"
            autoComplete="off"
            placeholder={`${translations["Search Brightcove"]}`}
          ></input>
          <div className="bar-line" />
        </label>
        <button type="submit">
          <SearchIcon iconColor={"#fff"} />
        </button>
      </form>
    </SearchStyle>
  );
};

export default Search;
