import React, { useContext } from "react";
import TopNavbarMobileStyle from "./TopNavbarMobileStyles";
import SignIn from "./Signin/Signin";
import Language from "./Language/Language";

const TopNavBarMobile = ({ setActiveSideBar, lang, translations }) => {
  return (
    <TopNavbarMobileStyle>
      <ul>
        <Language setActiveSideBar={setActiveSideBar} lang={lang} />
        <li>
          <a
            href="https://supportportal.brightcove.com/s/login/?language=en_US"
            target="_blank"
            className="support"
          >
            {translations["Support"]}
          </a>
        </li>
        {/* Signin */}
        <SignIn
          setActiveSideBar={setActiveSideBar}
          translations={translations}
        />
        {/* Phone */}
        <li>
          <a className="phone" href="tel:+1 888 882 1880">
            +1 888 882 1880
          </a>
        </li>
      </ul>
    </TopNavbarMobileStyle>
  );
};

export default TopNavBarMobile;
