import React, { useContext } from "react";
import SubItemStyles from "./SubItemStyles";

import ArrowLeft from "../image/ArrowLeft";

import SearchMobile from "../SearchMobile/SearchMobile";
import Link from "next/link";
import { MdClose } from "react-icons/md";

const SubItem = props => {
  const { tab, setActiveSubItem, setActiveSideBar, lang, translations } = props;

  // Function Check if internal or external Link
  const displayLink = item => {
    if (item.slug?.includes("https")) {
      return (
        // Uncoment IF you want on hover link image change based on the image tab
        // onMouseOver={() => item.itemImage && getImageSubNavItems(item.itemImage)}
        <a className="wrapper-link" href={item.slug} target="_blank">
          {item.label}
        </a>
      );
    } else {
      return (
        <Link
          prefetch
          // Uncoment IF you want on hover link image change based on the image tab
          // onMouseOver={() => item.itemImage && getImageSubNavItems(item.itemImage)}
          className="wrapper-link"
          href={`/${lang}/${item.slug}/`}
        >
          {item.label}
        </Link>
      );
    }
  };

  return (
    <SubItemStyles>
      {/* first column */}
      <div className="wrapper-first-back">
        <div className="back-button" onClick={() => setActiveSubItem(-1)}>
          <ArrowLeft /> <p>Back</p>
        </div>
        <div onClick={() => setActiveSideBar(false)}>
          <MdClose className="icon-close" />
        </div>
      </div>
      {/* Tabs */}
      <div>
        {/* Search */}
        {tab.label === "Search" && (
          <SearchMobile lang={lang} translations={translations} />
        )}
        <ul>
          {/* List SubNabItems */}
          {tab?.subNavItems?.map((item, index) => {
            return (
              <>
                {tab.label !== "Search" && (
                  <li key={index} className="subitem">
                    {displayLink(item.fields)}
                  </li>
                )}
              </>
            );
          })}
        </ul>
      </div>
    </SubItemStyles>
  );
};

export default SubItem;
