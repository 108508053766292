import styled from "styled-components";

const TopNavbarStyle = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  padding: 10px 0px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-between;
  /* Wrapper list  */
  .wrapper-list {
    display: flex;
    justify-content: flex-end;
    color: #000;
    /* sign and support */
    .signin,
    .support {
      padding: 0px 10px;
      z-index: 1;
      cursor: pointer;
    }
    /* Phone */
    .phone {
      padding-left: 10px;
      cursor: pointer;
    }
  }
  /* Dropdown signin */
  .toggle-signin {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .list-dropdown-signin {
    display: block;
    list-style: none;
    text-align: left;
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.95);
    a {
      color: white;
    }
    li {
      border: none;
      padding: 5px 10px;
      margin-bottom: 0px;
      color: white;

      /* Hover */
      &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.01);
        background-color: gray;
        opacity: 0.2;
      }
      button {
        border: none;
        background: none;
        color: white;
        cursor: pointer;
      }
    }
    -webkit-animation: scale-up-ver-top 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: scale-up-ver-top 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    @-webkit-keyframes scale-up-ver-top {
      0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
    }
    @keyframes scale-up-ver-top {
      0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
    }
  }
  .close-list {
    display: none;
  }
  /* Tablet max-width: 1279px */
  @media only screen and (max-width: 1279px) {
    padding: 10px 30px;
  }
  /* Tablet and Mobile: max-width: 768px */
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default TopNavbarStyle;
