import styled from "styled-components";

const MobileNavbarStyle = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  padding: 10px 30px 20px 30px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-between;
  /* Hamburger */
  .hamburger {
    cursor: pointer;
    width: 35px;
    height: 30px;
    margin-top: 8px;
  }
  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

export default MobileNavbarStyle;
