import styled from "styled-components";

const TabStyle = styled("div")<{ $navBgScroll: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-around;
  
  /* Container Tab */
  .container-tab {
    position: relative;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  
  /* Serch Icon for TAB */
  .Tab-list > .Tab > svg.svg-icon.search-icon {
    display: inline-block;
    width: 17px;
  }

  /* Tab-List */
  .Tab-list {
    .Tab {
      display: inline-block;
      position: relative;
      padding: 20px;
      cursor: default;
      font-size: 18px;
      text-transform: uppercase;
      font-family: "articulat-cf";
      color: ${({ $navBgScroll }) => ($navBgScroll ? "#fff" : "#000")};
    }
  }
  .active-tab {
    color: #fff !important;
    background: ${({ $navBgScroll }) =>
      $navBgScroll ? "#4ea9d1" : "rgba(0, 0, 0, 0.95)"};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    path {
      fill: #fff;
    }
  }

  /* Tab-panel */
  .Tab-panel {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    z-index: 1;
    color: white;
    padding: 40px;
    top: 66px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: ${({ $navBgScroll }) =>
      $navBgScroll ? "1px solid #4ea9d1" : "1px solid #fff"};
    background: rgba(0, 0, 0, 0.95);
    /* Animation */
    -webkit-animation: scale-up-ver-top 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: scale-up-ver-top 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    @-webkit-keyframes scale-up-ver-top {
      0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
    }
    @keyframes scale-up-ver-top {
      0% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
    }
  }

  /* Background Search Panel */
  .bg-Search {
    background: rgba(0, 0, 0, 0.95);
    width: 580px;
    left: 350px;
    right: 0;
    display: block;
  }

  /* Tablet and Mobile: max-width: 1090px */
  @media only screen and (max-width: 1110px) {
    justify-content: space-between;
    padding: 0px 25px;
    .container-tab {
      display: contents;
    }
      
    /* Tab-List */
    .Tab-list {
      width: 100%;
      display: contents;
      /* Tab */
      .Tab {
        font-size: 16px;
        padding: 20px 5px 20px 5px;
        text-transform: uppercase;
        font-family: "articulat-cf";
      }
    }
    /* Tab-panel */
    .Tab-panel {
      width: 100%;
      left: 0;
      top: 126px;
    }
    /* Background Search Panel */
    .bg-Search {
      width: 100%;
    }
  }
`;

export default TabStyle;
