import styled from "styled-components";

const NavbarStyle = styled("div")<{ $navBgScroll: boolean }>`
  position: relative;
  z-index: 100;
  height: 118px;
  display: inline-block;
  .wrapper-nav {
    /* Fixed */
    position: fixed;
    top: ${({ $navBgScroll }) => ($navBgScroll ? "-57px" : "0px")};
    transition: top 0.3s; /* Transition effect when sliding down (and up) */
    z-index: 1;
    /* End */
    display: flex;
    flex-direction: column;
    background-color: ${({ $navBgScroll }) =>
      $navBgScroll ? "rgba(0, 0, 0, 0.95)" : "#fff"};
    align-items: baseline;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  /* Tablet and Mobile: max-width: 800px */
  @media only screen and (max-width: 899px) {
    display: none;
  }
`;

export default NavbarStyle;
