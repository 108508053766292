import React from 'react'
import { BannerStyles } from './styles';

const Banner = ({lang}) => {
  return (
    <BannerStyles $lang={lang}>
      <p>
        {lang === "ja"
          ? "Brightcove AIのご紹介 - 世界で最もスマートで、最速かつ信頼性の高い自動エンゲージメントプラットフォームです。"
          : "Introducing Brightcove AI- the world’s smartest, fastest, most reliable automated engagement platform. "}
        <a
          href={`${lang === "ja" ? "https://campaigns.brightcove.com/ja/ai-solutions/" : "https://campaigns.brightcove.com/ai-solutions/"}`}
        >
          {lang === "ja" ? "詳しくはこちらをご覧ください。" : "Learn More"}
        </a>
      </p>
    </BannerStyles>
  );
}

export default Banner