import React from "react";

const ArrowLeft = () => {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.5 9L1.5 5L5.5 1" stroke="#000" strokeWidth="1.75"></path>
    </svg>
  );
};

export default ArrowLeft;
