import styled from "styled-components";
import { BREAK_POINTS } from "@/src/lib/breakPoints";

export const RefContainer = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  overflow-x: hidden;
  top: 0;
  left: 0;
  z-index: 1000;
  visibility: hidden;
  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    display: none;
  }
`;
export const SideCTAsWrapper = styled("div")<{ $hide: boolean }>`
  pointer-events: none;
  overflow-x: hidden;
  display: flex;
  visibility: ${({ $hide }) => ($hide ? "hidden" : "visible")};
  right: 0;
  position: fixed;
  padding: 0;
  margin: 0;
  z-index: 2;
  top: 70%;
  align-items: flex-end;
  justify-content: flex-end;

  .side-cta-container {
    pointer-events: initial;
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
    width: fit-content;
    border-radius: 3px;
    background: transparent;
    .side-cta-item {
      padding: 20px;
      border-bottom: 2px solid black;
      z-index: 1;
      background: #ffce00;
      transform: translateX(calc(100% - 50px));
      transition: transform 300ms ease-in-out;
      &:hover {
        transform: translateX(0);
      }
      &:nth-of-type(2) {
        z-index: 10;
        &:before {
          content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5V19' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 12H19' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          height: calc((100% / 3) + 4px);
          width: 25%;
          position: absolute;
          background: #ffce00;
          top: calc((100% / 3) + 4px);
          left: -25%;
          text-align: center;
          padding: 20px 0;
          opacity: 1;
          transition: opacity 450ms ease-in-out;
          transition-delay: 300ms;
          z-index: -10;
          border: 3px;
          display: none;
        }
        &.plus {
          content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5V19' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 12H19' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
        &.minus {
          content: url("data:image/svg+xml,%3Csvg width='24' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H15' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }
      a {
        color: black;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 0px;
        &.icon {
          height: 24px;
          width: 24px;
          font-size: 24px;
          margin-right: 12px;
          transform: translateY(3px);
          background-size: cover;
          &.calendar {
          }
          &.phone {
          }
        }
        strong {
          font-size: 14px;
        }
        &.hover {
          strong {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &.show {
    .side-cta-container {
      transform: translateX(0);
      .side-cta-item {
        transform: translateX(0);
        &:nth-of-type(2) {
          &:before {
            content: url("data:image/svg+xml,%3Csvg width='24' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H15' stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    position: fixed;
    .side-cta-container {
      transform: translateX(100%);
      .side-cta-item {
        transform: translateX(0%);
        height: auto;
        &:nth-of-type(2) {
          &:before {
            display: block;
            top: -2px;
            height: calc(100% + 4px);
          }
        }
      }
    }
  }
`;
