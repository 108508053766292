"use client";
import React, { useState, useEffect } from "react";
import { TfiArrowUp } from "react-icons/tfi";

function ScrollToTopButton({
  translations,
  color = "black",
  classes,
}: {
  translations: any;
  color?: "black" | "white";
  classes?: string;
}) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <div className={"wrapper-btn " + classes}>
        <button
          style={{ backgroundColor: color }}
          onClick={scrollToTop}
          className="scroll-to-top-button"
        >
          <TfiArrowUp color={color === "white" ? "black" : "white"} />
        </button>
        <p style={{ color }}>{translations["BACK TO TOP"]}</p>
      </div>
    )
  );
}

export default ScrollToTopButton;
