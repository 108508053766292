import styled from "styled-components";
import { BREAK_POINTS } from "@/src/lib/breakPoints";

export const LightboxWrapper = styled.section`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation: show-animation 500ms ease;

  transition: opacity 500ms ease;
  .video-wrap {
    width: 100%;
  }
  .video-js {
    width: 100% !important;
    height: 0px !important;
    padding-top: 56.25%;
    position: absolute;
  }
  > div {
    background: #000;
    color: #fff;
    padding: 0 !important;
    min-width: 960px;
    min-height: 540px;
    overflow: scroll;
    > button {
      color: #fff;
      background: transparent;
      border: 0;
      cursor: pointer;
      outline: none;
      position: absolute;
      right: 10px;
      top: 10px;
      transition: transform 300ms ease;
      transform-origin: 11px 19px;
      z-index: 999;
      svg {
        font-size: 35px;
      }
    }
  }

  @keyframes show-animation {
    from {
      opacity: 0;
      transform: scale(1.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    overflow: auto;
    overflow-y: scroll;
    height: 100%;
    > div {
      width: 100vw;
      min-width: 100vw;
      min-height: 200px;
      .video-js {
        width: 100% !important;
        height: 0px !important;
        padding-top: 56.25%;
      }
    }
    form {
      max-height: calc(100vh - 210px);
      overflow-y: scroll;
    }
  }
  @media only screen and (${BREAK_POINTS.TABLET_LANDSCAPE_MAX}) {
    > div {
      width: 100vw;
      min-width: 100vw;
      min-height: 200px;
      .video-js {
        width: 100% !important;
        height: 0px !important;
        padding-top: 56.25%;
      }
    }
  }
`;
