"use client";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import ga from "@/src/lib/googleAnalyticsService";

import * as Styles from "./SideCTAsStyles";
import { useLightboxData } from "@/src/context/lightboxContext";
import { FaPhoneAlt } from "react-icons/fa";
import { FiCalendar } from "react-icons/fi";

const SideCTAs = ({ hide, items, translations, lang }) => {
  const lightboxData = useLightboxData();
  const [show, setShow] = useState(false);

  // const linkCleaner = (link, lang) => {
  //   return link.includes('http') ? link : `https://brightcove.com/${lang}/${link}`
  // }
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const getFormContent = (headline, lang, item) => {
    const formContent = {
      phone: {
        node_locale: lang,
        slug: "",
        smallText: translations["Talk to Us"],
        title: translations["See how Brightcove can work for you"],
        body: null,
        eloquaFormName: "contactSales",
        offerId: "7016f000001aZbXAAU",
        salesforceOfferId: "7018c000001qVahAAE",
        submitText: translations["Get Started"],
        thanksTitle: translations["THANKS FOR GETTING IN TOUCH"],
        thanksMessage:
          translations[
            "Check your inbox for confirmation of your request and the next steps"
          ],
        comments: "",
        extraFields: {},
        eventObj: {
          eventCategory: "Side CTA",
          eventAction: "Form submit",
          eventLabel: `Sales - ${item.node_locale} | ${window.location.href}`,
        },
        formElementId: "csr-form",
        translations,
      },
      calendar: {
        node_locale: lang,
        slug: "",
        smallText: translations["Get a Demo"],
        title: translations["SEE THE BRIGHTCOVE PLATFORM IN ACTION"],
        body: translations[
          "Discover how video can help you grow your business"
        ],
        eloquaFormName: "contactSales",
        offerId: "7016f000001aZbXAAU",
        salesforceOfferId: "7018c000001qVamAAE",
        submitText: translations["Get Started"],
        thanksTitle: translations["THANKS FOR GETTING IN TOUCH"],
        thanksMessage:
          translations[
            "Check your inbox for confirmation of your request and the next steps"
          ],
        comments: "",
        extraFields: {},
        eventObj: {
          eventCategory: "Side CTA",
          eventAction: "Form submit",
          eventLabel: `Demo- ${lang} | ${window.location.href}`,
        },
        formElementId: "demo-form",
        translations,
      },
    };
    return formContent[headline];
  };
  const nameLookup = {
    phone: "Sales",
    calendar: "Demo",
    paper: "Report",
  };
  const isBrowser = typeof window !== "undefined";

  const analyticsTrack = item => {
    if (isBrowser) {
      return ga.trackEvent({
        eventCategory: "Side CTA",
        eventAction: "Lightbox Form Open",
        eventLabel: `${nameLookup[item.icon]} - ${lang} | ${window.location.href}`,
      });
    }
  };
  return (
    <>
      <Styles.RefContainer ref={ref} />
      <Styles.SideCTAsWrapper
        $hide={hide || false}
        className={show || inView ? "show" : ""}
      >
        <div
          className="side-cta-container"
          onClick={() => setShow(!show)}
          onKeyPress={() => setShow(!show)}
          role="menuitem"
          tabIndex={0}
        >
          {items &&
            items.map(item => {
              const trial = item.link.includes(
                "https://register.brightcove.com/"
              );
              const download = item.link.includes("/resources/downloads/");
              const demo = item.link.includes("get-demo");
              return (
                <div className="side-cta-item" key={item.icon} id={item.icon}>
                  <a
                    href={trial || download || demo ? item.link : "#"}
                    onClick={e => {
                      if (!trial && !download && !demo) {
                        e.preventDefault();
                        // ctaFunction()
                        lightboxData?.setLightBoxData({
                          open: true,
                          type: "form",
                          content: getFormContent(item.icon, lang, item),
                        });
                        analyticsTrack(item);
                      }
                    }}
                  >
                    {item.icon === "phone" ? (
                      <FaPhoneAlt size={18} className="icon phone" />
                    ) : (
                      <FiCalendar size={18} className="icon calendar" />
                    )}
                    <strong>{translations[item.headline]}</strong>
                  </a>
                </div>
              );
            })}
        </div>
      </Styles.SideCTAsWrapper>
    </>
  );
};
export default SideCTAs;
