import styled from "styled-components";

const TopNavbarMobileStyle = styled.div`
  li {
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid #3f4b66;
    color: #000;
    margin-bottom: 0;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #000;
      color: #fff;
      path {
        fill: #fff;
      }
      a {
        color: #fff;
      }
    }
  }
  .support,
  .phone {
    padding: 20px;
    display: flex;
    width: 100%;
  }
`;

export default TopNavbarMobileStyle;
