"use client";
import React, { useEffect } from "react";
import Cookie from "js-cookie";

function SEO() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const cookieAttrs = [
      "cid",
      "pid",
      "utm_source",
      "utm_campaign",
      "utm_medium",
      "utm_content",
      "utm_term",
      "utm_id",
      "gclid",
    ];

    // if we have params, check them and update the cookies
    if (params.toString().length > 0) {
      const cookieIDs = ["BC_REG", "BC_MKTG_AUTO"];
      const { referrer } = document;
      cookieIDs.forEach(item => {
        let vals;
        const exists = Cookie.get(item);
        if (exists) {
          const existing = JSON.parse(decodeURIComponent(exists));
          cookieAttrs.forEach(attr => {
            if (params.get(attr) !== null) {
              existing[attr] = params.get(attr);
            }
          });
          vals = existing;
        } else {
          vals = {};
          cookieAttrs.forEach(attr => {
            if (params.get(attr) !== null) {
              vals[attr] = params.get(attr);
            }
          });
        }
        vals.referring_url = referrer;
        const encoded = encodeURI(JSON.stringify(vals));
        Cookie.set(item, encoded, {
          expires: 1,
          domain: window.location.hostname.replace("www.", ""),
          path: "/",
        });
      });
    }
  }, []);

  return <></>;
}

export default SEO;
