"use client";
import React, { useEffect } from "react";
import { Container, Row } from "react-grid-system";

import * as Styles from "./LightboxStyles";
import Cookies from "js-cookie";
import { useLightboxData } from "@/src/context/lightboxContext";
import { sfmcCookie } from "@/src/lib/sfmcCookie";
import loadable from "@loadable/component";
import { AiOutlineClose } from "react-icons/ai";

const Lightbox = ({ translations, lang }) => {
  const lightBoxData = useLightboxData();
  let formData =
    typeof lightBoxData!.lightBoxData.content === "object"
      ? lightBoxData!.lightBoxData.content
      : {
          eloquaFormName: lightBoxData!.lightBoxData.content,
          translations,
        };
  const onSuccess = success => {
    // setup
    const thisPlayer = success.ref;
    thisPlayer.on("loadedmetadata", () => {
      thisPlayer.play();
    });
    if (window && window.location) {
      sfmcCookie();
      success.setAttribute("data-bc-known-user", 1);
      success.setAttribute("data-bc-custom-guid", Cookies.get("_sfmc_id_"));
      // success.ref.el_.setAttribute('data-bc-known-user', 1)
      // success.ref.el_.setAttribute('data-bc-custom-guid', Cookies.get('_sfmc_id_'))
    }
  };

  useEffect(() => {
    document.documentElement.style.overflow = lightBoxData!.lightBoxData.open
      ? "hidden"
      : "";
    //eslint-disable-next-line
  }, [lightBoxData!.lightBoxData.open]);

  const handleEscape = e => {
    if (e.keyCode === 27) {
      lightBoxData!.setLightBoxData({
        open: false,
        type: "",
        content: "",
        playerId: "",
      });
    }
  };

  useEffect(() => {
    formData =
      //eslint-disable-next-line
      typeof lightBoxData!.lightBoxData.content === "object"
        ? { ...lightBoxData!.lightBoxData.content, translations }
        : { eloquaFormName: lightBoxData!.lightBoxData.content, translations };
    document.addEventListener("keydown", handleEscape);
    //eslint-disable-next-line
  }, [lightBoxData!.lightBoxData]);
  const PlayerLoader = loadable(
    () => import("@brightcove/react-player-loader")
  );
  const FormComponent = loadable(
    () => import("@/src/components/Contentful_Components/Form/Form")
  );
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);
    const autoplay = params.get("autoplay");
    // setAutoplay(params.get('autoplay'))
    if (autoplay) {
      const cta = document.querySelector("#hero-cta");
      // lightboxData.open = true;
      // if(cta){
      //   cta.click()
      // }
    }
  }
  return lightBoxData!.lightBoxData.open ? (
    <Styles.LightboxWrapper>
      <Container>
        <button
          type="button"
          onClick={() =>
            lightBoxData!.setLightBoxData({
              open: false,
              type: "",
              content: "",
              playerId: "",
            })
          }
        >
          <AiOutlineClose color="#fff" />
        </button>
        <Row gutterWidth={0}>
          {lightBoxData!.lightBoxData.type === "form" && (
            <FormComponent
              {...formData}
              lang={lang}
              translations={translations}
            />
          )}
          {lightBoxData!.lightBoxData.type === "video" &&
            lightBoxData!.lightBoxData.content && (
              <div className="video-wrap">
                <PlayerLoader
                  playerId={
                    lightBoxData!.lightBoxData.playerId
                      ? lightBoxData!.lightBoxData.playerId
                      : "RUsPQ8qzl"
                  }
                  baseUrl="https://players.brightcove.net"
                  refNode="video-wrap"
                  accountId="1160438696001"
                  options={{ autoplay: true }}
                  videoId={lightBoxData!.lightBoxData.content}
                  // onSuccess={(e) => onSuccess(e)}
                  onEmbedCreated={el => {
                    sfmcCookie();
                    el.setAttribute("data-bc-known-user", 1);
                    el.setAttribute(
                      "data-bc-custom-guid",
                      Cookies.get("_sfmc_id_")
                    );
                  }}
                />
              </div>
            )}
        </Row>
      </Container>
    </Styles.LightboxWrapper>
  ) : null;
};

export default Lightbox;
