"use client";
import React, { useState, useLayoutEffect } from "react";
import NavbarStyles from "./NavbarStyles";
import Tab from "./DesktopNavbar/Tab/Tab";
import TopNavbar from "./DesktopNavbar/TopNavbar/TopNavbar";
import MobileNavbar from "./MobileNavbar/MobileNavbar";
import Banner from "./Banner";

export default function Navbar({ lang, navData, translations, hasBanner }) {
  const [navBgScroll, setNavBgScroll] = useState(false); // background navbar
  const [activeTab, setActiveTab] = useState(-1); // Actual opening Tab Index
  // Function on scroll
  useLayoutEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);
  // on scroll background Navbar
  const changeNavBg = () => {
    window.scrollY >= 110 ? setNavBgScroll(true) : setNavBgScroll(false);
  };

  return (
    <>
      {/* Mobile */}
      <MobileNavbar lang={lang} translations={translations} navData={navData} />
      {/* Desktop */}
      <NavbarStyles $navBgScroll={navBgScroll} onScroll={changeNavBg}>
        <div className="wrapper-nav">
          {/* Top Navbar */}
          <TopNavbar lang={lang} translations={translations} />
          {/* Tab */}
          <Tab
            navData={navData}
            lang={lang}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            navBgScroll={navBgScroll}
            translations={translations}
          />
        </div>
      </NavbarStyles>
      {hasBanner && <Banner lang={lang} />}
    </>
  );
}
