import React, { useState, useContext } from "react";
import DropDownLang from "./DropDownLang/DropDownLang";
import TopNavbarStyles from "./TopNavbarStyles";
import Logo from "@/src/components/Global_Components/Logo/Logo";

const TopNavbar = ({ lang, translations }) => {
  const [listActiveSignin, setListActiveSignin] = useState(false);
  return (
    <TopNavbarStyles>
      {/* Logo */}
      <Logo lang={lang} />
      <div className="wrapper-list">
        {/* Language list  */}
        <DropDownLang lang={lang} />
        {/* Support */}
        <a
          href="https://supportportal.brightcove.com/s/login/?language=en_US"
          target="_blank"
          className="support"
        >
          {translations["Support"]}
        </a>
        {/* Signin */}
        <div className="signin" onMouseLeave={() => setListActiveSignin(false)}>
          <button
            className="toggle-signin"
            onClick={() => setListActiveSignin(true)}
          >
            {translations["Sign In"]}
          </button>
          <ul
            className={listActiveSignin ? "list-dropdown-signin" : "close-list"}
          >
            <li>
              <a href="https://signin.brightcove.com/" target="_blank">
                Video Cloud
              </a>
            </li>
            <li>
              <a href="https://app.zencoder.com/login" target="_blank">
                Zencoder
              </a>
            </li>
          </ul>
          {/* Phone */}
        </div>
        <a
          className="phone"
          id="web-phone"
          href={`tel:${translations["+1 888 882 1880"]}`}
        >
          {translations["+1 888 882 1880"]}
        </a>
      </div>
    </TopNavbarStyles>
  );
};

export default TopNavbar;
