import React from "react";
import styled from "styled-components";
import "./col.scss";

const ColWrapper = styled("div")<{
  $align?: string;
  $gutter?: string;
}>`
  box-sizing: border-box;
  min-height: 1px;
  position: relative;
  padding: ${({ $gutter }) => `0px ${$gutter || 30}px`};
  align-items: ${({ $align }) => $align || "normal"};
  margin-left: 0%;
  right: auto;
  left: auto;
`;
interface ColStyleProps {
  gutter?: string;
  lg?: number;
  md?: number;
  sm?: number;
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  align?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  role?: string;
  children?: React.ReactNode;
  alt?: string;
}

const ColStyle: React.FC<ColStyleProps> = ({
  gutter = "30", // Default value
  lg,
  md,
  sm,
  style,
  className,
  id,
  align,
  onClick,
  role,
  children,
  alt,
}) => {
  const largeClassName = lg ? `lg-${lg}` : "";
  const medClassName = md ? `md-${md}` : "";
  const smClassName = sm ? `sm-${sm}` : "";
  const allClasses = `${largeClassName} ${medClassName} ${smClassName}`;
  return (
    <ColWrapper
      id={id}
      $align={align}
      style={style}
      className={`${className && className} ${allClasses}`}
      $gutter={gutter}
      role={role}
      alt={alt}
      // @ts-ignore
      onClick={onClick ? e => onClick(e) : null}
    >
      {children}
    </ColWrapper>
  );
};

export default ColStyle;
