import React, { useState } from "react";
import LanguageStyles from "./LanguageStyles";

import ArrowRight from "../../image/ArrowRight";

import ArrowLeft from "../../image/ArrowLeft";

import { MdClose } from "react-icons/md";

import { languageMap } from "@/src/lib/languageMap";
import { useRouter } from "next/navigation";

const Language = ({ setActiveSideBar, lang }) => {
  const router = useRouter();
  const [activeDetails, setActiveDetails] = useState(false);

  const langChange = langStr => {
    if (window && window.location) {
      const pathItems = window.location.pathname.split("/");
      pathItems[1] = langStr;
      const newPath = pathItems.join("/");
      return router.push(newPath);
    }
    return router.push(`/${langStr}`);
  };
  return (
    <LanguageStyles>
      <div className="toggle" onClick={() => setActiveDetails(true)}>
        {languageMap[lang]} <ArrowRight />
      </div>
      {/* details */}
      {activeDetails && (
        <div className="detail-container">
          <div className="wrapper-first-back">
            <div
              className="back-button"
              onClick={() => setActiveDetails(false)}
            >
              <ArrowLeft /> <p>Back</p>
            </div>
            <div onClick={() => setActiveSideBar(false)}>
              <MdClose className="icon-close" />
            </div>
          </div>
          <div>
            <ul>
              {Object.entries(languageMap).map((language, i) => (
                <li
                  className="lang"
                  key={i}
                  onClick={() => langChange(language[0])}
                >
                  {language[1]}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </LanguageStyles>
  );
};

export default Language;
