import React, { useState } from "react";
import DropDownLangStyles from "./DropDownLangStyles";
import { languageMap } from "@/src/lib/languageMap";
import { useRouter } from "next/navigation";

const DropDownLang = ({ lang }) => {
  const router = useRouter();
  const [listActive, setListActive] = useState(false);

  // Function to change language
  const langChange = langStr => {
    if (window && window.location) {
      const pathItems = window.location.pathname.split("/");
      pathItems[1] = langStr;
      const newPath = pathItems.join("/");
      return router.push(newPath);
    }
    return router.push(`/${langStr}`);
  };

  return (
    <DropDownLangStyles onMouseLeave={() => setListActive(false)}>
      <div className="container">
        <div className="menu">
          {/* Toggle button */}
          <button onClick={() => setListActive(true)} className="toggle">
            {languageMap[lang]}
          </button>
          {/* List all Language */}
          <ul className={listActive ? "list-dropdown " : "close-list"}>
            {Object.entries(languageMap).map((language, i) => (
              <li key={i}>
                <button onClick={() => langChange(language[0])}>
                  {language[1]}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </DropDownLangStyles>
  );
};

export default DropDownLang;
