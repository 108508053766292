import styled from "styled-components";

const SubNavItemsStyle = styled.div`
  a.wrapper-link {
    display: block;
    color: #fff;
    padding: 10px 0px;
    border-radius: 8px;
    font-size: ${({ lang }) => (lang === "de" ? "16px" : "20px")};
    letter-spacing: 0.2px;
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    font-weight: bold;
    @-webkit-keyframes tracking-in-expand {
      0% {
        letter-spacing: -0.5em;
        opacity: 0;
      }
      40% {
        opacity: 0.6;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes tracking-in-expand {
      0% {
        letter-spacing: -0.5em;
        opacity: 0;
      }
      40% {
        opacity: 0.6;
      }
      100% {
        opacity: 1;
      }
    }
    /* Hover */
    &:hover {
      transition: all 0.2s ease-in-out;
      transform: scale(1.01);
      color: #999999;
    }
  }
  /* Tablet and Mobile: max-width: 1110px */
  @media only screen and (max-width: 1300px) {
    a.wrapper-link {
      font-size: ${({ lang }) => (lang === "de" ? "14px" : "16px")};
    }
  }
  /* Tablet and Mobile: max-width: 1110px */
  @media only screen and (max-width: 1110px) {
    a.wrapper-link {
      font-size: ${({ lang }) => (lang === "de" ? "12px" : "14px")};
    }
    }
  }
`;

export default SubNavItemsStyle;
