import styled from "styled-components";

const SearchMobileStyles = styled.div`
  display: flex;
  background-color: black;
  padding: 40px;
  justify-content: center;
  form {
    max-width: 100%;
    display: flex;
    border-radius: 8px;
    text-align: left;
  }
  input {
    padding: 10px;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 20px;
  }
  .bar-line {
    background: linear-gradient(to left, #4ea9d1, #aa6c7e, #f76531, #efd900);
    width: 100%;
    height: 2px;
    -webkit-animation: scale-up-hor-left 1.2s
      cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-hor-left 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  button {
    border: none;
    height: 40px;
    background-color: transparent;
    color: #fff;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
      1.2s both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 1.2s
      both;
    @-webkit-keyframes scale-up-center {
      0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
      }
      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
    @keyframes scale-up-center {
      0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
      }
      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  textarea:focus,
  input:focus {
    outline: none;
  }

  /* Search */
  .svg-icon.search-icon {
    display: inline-block;
    width: 50px;
    height: 48px;
    cursor: pointer;
    /* On hover: blue strokes */
    &:focus,
    &:hover {
      .search-path {
        stroke: #4ea9d1;
      }
    }

    /* On click: thicker black strokes  */
    &:active {
      .search-path {
        stroke: #111516;
        stroke-width: 2px;
      }
    }
  }
  /* Animation */
  @-webkit-keyframes scale-up-hor-left {
    0% {
      -webkit-transform: scaleX(0.4);
      transform: scaleX(0.4);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
    }
  }
  @keyframes scale-up-hor-left {
    0% {
      -webkit-transform: scaleX(0.4);
      transform: scaleX(0.4);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
    }
  }
  @media only screen and (max-width: 598px) {
    padding: 20px;
  }
`;

export default SearchMobileStyles;
