import {
  FaLinkedinIn,
  FaFacebook,
  FaInstagram,
  FaRssSquare,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
const year = new Date();
const navItems = {
  topnav: {
    laguajes: {
      label: "LangSelector",
      slug: "LangSelector",
      link: false,
      useLink: false,
      class: "",
      items: [
        {
          label: "English",
          slug: "en",
          class: "",
          useLink: true,
        },
        {
          label: "Français",
          slug: "fr",
          class: "",
          useLink: false,
        },
        {
          label: "Deutsch",
          slug: "de",
          class: "",
          useLink: false,
        },
        {
          label: "Español",
          slug: "es",
          class: "",
          useLink: true,
        },
        {
          label: "日本語",
          slug: "ja",
          class: "",
          useLink: true,
        },
        {
          label: "한국어",
          slug: "ko",
          class: "",
          useLink: true,
        },
      ],
    },
    support: {
      label: "Support",
      slug: "https://supportportal.brightcove.com/s/login",
      link: true,
      useLink: false,
      class: "",
      items: [],
    },
    signin: {
      label: "Sign In",
      slug: "signin",
      link: false,
      useLink: true,
      class: "icon-account icon",
      items: [
        {
          label: "Video Cloud",
          slug: "video-cloud-sign-in",
          link: "https://signin.brightcove.com/",
          class: "",
          useLink: false,
        },
        {
          label: "Zencoder",
          slug: "zencoder-sign-in",
          link: "https://app.zencoder.com/login",
          class: "",
          useLink: false,
        },
      ],
    },
    phones: {
      label: "PhoneSelector",
      slug: "PhoneSelector",
      link: false,
      useLink: false,
      class: "phone",
      items: [
        {
          label: "+1 888 882 1880",
          slug: "+1 888 882 1880",
          class: "phone",
          useLink: false,
        },
      ],
    },
  },
  mobilenav: {
    search_mobile: {
      label: "Search",
      slug: "searchBox",
      link: false,
      useLink: false,
      class: "icon-search icon",
      columns: [],
    },
    hamburguer: {
      label: "mobileMenu",
      slug: "mobileMenu",
      link: false,
      useLink: false,
      class: "icon-hamburger icon",
      columns: [],
    },
  },
  header: {
    "why-brightcove": {
      label: "Why Brightcove",
      slug: "why-brightcove",
      link: false,
      useLink: false,
      class: "",
      columns: [
        [
          {
            label: "Why Brightcove",
            slug: "why-brightcove",
            class: "subnav-item-medium",
            hoverColor: "#f7cd54",
            altColor: "#ea5f34",
            useLink: true,
          },
          {
            label: "- Monetize content",
            slug: "why-brightcove/monetize-content",
            class: "subnav-item-medium",
            hoverColor: "#f7cd54",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "- Engage people",
            slug: "why-brightcove/engage-people",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "- Sell your product",
            slug: "why-brightcove/sell-your-product",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "Customer Stories",
            slug: "customers",
            class: "subnav-item-medium",
            hoverColor: "#f7cd54",
            altColor: "#ea5f34",
            useLink: true,
          },
          {
            label: "Global Services",
            slug: "services",
            class: "subnav-item-medium",
            hoverColor: "#f7cd54",
            altColor: "#ea5f34",
            useLink: true,
          },
          {
            label: "Channel Partners",
            slug: "partners",
            class: "subnav-item-medium",
            hoverColor: "#f7cd54",
            altColor: "#ea5f34",
            useLink: true,
          },
          {
            label: "Brightcove Marketplace",
            slug: "https://marketplace.brightcove.com/",
            class: "subnav-item-medium",
            hoverColor: "#f7cd54",
            altColor: "#ea5f34",
            useLink: true,
          },
          // {
          //   label: 'Become a Partner',
          //   slug: 'become-a-partner',
          //   class: 'subnav-item-medium',
          //   hoverColor: '#f7cd54',
          //   altColor: '#ea5f34',
          //   useLink: true,
          // },
        ],
      ],
    },
    products: {
      label: "Products",
      slug: "products",
      link: false,
      useLink: false,
      class: "",
      columns: [
        [
          // {
          //   label: 'Video Essentials',
          //   slug: '',
          //   class: 'subnav-item-header',
          //   hoverColor: '',
          //   altColor: '',
          //   useLink: true,
          // },
          {
            label: "Video Communications Platform",
            slug: "products/online-video-platform",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          // {
          //   label: 'Live',
          //   slug: 'products/live',
          //   class: 'subnav-item-list',
          //   hoverColor: '#0b1e89',
          //   altColor: '#70e5e2',
          //   useLink: true,
          // },
          {
            label: "Audience-Insights",
            slug: "products/audience-insights",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "Video Marketing Suite",
            slug: "products/video-marketing-suite",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "- Marketing Studio",
            slug: "products/marketing-studio",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "Enterprise Video Suite",
            slug: "products/enterprise-video-suite",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "Virtual Events",
            slug: "products/virtual-events-experience",
            class: "subnav-item-medium",
            hoverColor: "#70e5e2",
            altColor: "#0b1e89",
            useLink: true,
          },
          {
            label: "CorpTV",
            slug: "products/corptv",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "OTT",
            slug: "products/beacon-ott-platform",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          // {
          //   label: 'Player',
          //   slug: 'products/player',
          //   class: 'subnav-item-medium',
          //   hoverColor: '#0b1e89',
          //   altColor: '#70e5e2',
          //   useLink: true,
          // },
          {
            label: "Video Encoding",
            slug: "products/zencoder",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
          {
            label: "interactivity",
            slug: "products/interactivity",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: true,
          },
        ],
        // [
        // {
        //   label: 'CorpTV',
        //   slug: 'products/corptv',
        //   class: 'subnav-item-medium',
        //   hoverColor: '#0b1e89',
        //   altColor: '#70e5e2',
        //   useLink: true,
        // },
        // {
        //   label: 'OTT',
        //   slug: 'products/beacon-ott-platform',
        //   class: 'subnav-item-medium',
        //   hoverColor: '#0b1e89',
        //   altColor: '#70e5e2',
        //   useLink: true,
        // },
        // // {
        // //   label: 'Player',
        // //   slug: 'products/player',
        // //   class: 'subnav-item-list',
        // //   hoverColor: '#0b1e89',
        // //   altColor: '#70e5e2',
        // //   useLink: true,
        // // },
        // {
        //   label: 'Video Encoding',
        //   slug: 'products/zencoder',
        //   class: 'subnav-item-list',
        //   hoverColor: '#0b1e89',
        //   altColor: '#70e5e2',
        //   useLink: true,
        // },
        // {
        //   label: 'Brightcove Marketplace',
        //   slug: 'https://marketplace.brightcove.com/',
        //   class: 'subnav-item-list',
        //   hoverColor: '#0b1e89',
        //   altColor: '#70e5e2',
        //   useLink: false,
        // },
        // ],
        // [
        //   {
        //     label: 'Video Solutions',
        //     slug: '',
        //     class: 'subnav-item-header',
        //     hoverColor: '',
        //     altColor: '',
        //     useLink: true,
        //   },
        //   // {
        //   //   label: 'Virtual Events',
        //   //   slug: 'products/virtual-events-experience',
        //   //   class: 'subnav-item-list nav-large',
        //   //   hoverColor: '#70e5e2',
        //   //   altColor: '#0b1e89',
        //   //   useLink: true,
        //   // },
        //   // {
        //   //   label: 'Enterprise Video Suite',
        //   //   slug: 'products/enterprise-video-suite',
        //   //   class: 'subnav-item-list nav-large',
        //   //   hoverColor: '#70e5e2',
        //   //   altColor: '#0b1e89',
        //   //   useLink: true,
        //   // },
        //   // {
        //   //   label: 'Video Marketing Suite',
        //   //   slug: 'products/video-marketing-suite',
        //   //   class: 'subnav-item-list nav-large',
        //   //   hoverColor: '#70e5e2',
        //   //   altColor: '#0b1e89',
        //   //   useLink: true,
        //   // }
        // ],
        // [
        //   {
        //     label: 'Video Add-Ons',
        //     slug: '',
        //     class: 'subnav-item-header',
        //     hoverColor: '',
        //     altColor: '',
        //     useLink: true,
        //   },
        //   {
        //     label: 'Brightcove Cloud Playout',
        //     slug: 'products/brightcove-cloud-playout',
        //     class: 'subnav-item-list nav-xsmall',
        //     hoverColor: '#0b1e89',
        //     altColor: '#70e5e2',
        //     useLink: true,
        //   },
        //   {
        //     label: 'Brightcove Campaign',
        //     slug: 'products/brightcove-campaign',
        //     class: 'subnav-item-list nav-xsmall',
        //     hoverColor: '#0b1e89',
        //     altColor: '#70e5e2',
        //     useLink: true,
        //   },
        //   {
        //     label: 'Brightcove Engage',
        //     slug: 'products/brightcove-engage',
        //     class: 'subnav-item-list nav-xsmall',
        //     hoverColor: '#0b1e89',
        //     altColor: '#70e5e2',
        //     useLink: true,
        //   },
        //   {
        //     label: 'Brightcove Gallery',
        //     slug: 'products/gallery',
        //     class: 'subnav-item-list nav-xsmall',
        //     hoverColor: '#0b1e89',
        //     altColor: '#70e5e2',
        //     useLink: true,
        //   },
        //   {
        //     label: 'Brightcove Social',
        //     slug: 'products/social',
        //     class: 'subnav-item-list nav-xsmall',
        //     hoverColor: '#0b1e89',
        //     altColor: '#70e5e2',
        //     useLink: true,
        //   },
        //   {
        //     label: 'Brightcove SSAI',
        //     slug: 'products/ssai',
        //     class: 'subnav-item-list nav-xsmall',
        //     hoverColor: '#0b1e89',
        //     altColor: '#70e5e2',
        //     useLink: true,
        //   },
        //   {
        //     label: 'Brightcove Interactivity',
        //     slug: 'products/interactivity',
        //     class: 'subnav-item-list nav-xsmall',
        //     hoverColor: '#0b1e89',
        //     altColor: '#70e5e2',
        //     useLink: true,
        //   },
        // ],
      ],
    },
    solutions: {
      label: "Solutions",
      slug: "solutions",
      link: false,
      useLink: false,
      class: "",
      columns: [
        [
          {
            label: "Media and Broadcasters",
            slug: "solutions/media-broadcasters",
            class: "subnav-item-medium nav-large",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Healthcare",
            slug: "solutions/healthcare",
            class: "subnav-item-medium nav-large",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Financial-Services",
            slug: "solutions/financial-services",
            class: "subnav-item-medium nav-large",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Marketers",
            slug: "solutions/marketers",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Arts and Entertainment",
            slug: "solutions/arts-entertainment",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Publishers",
            slug: "solutions/publishers",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Manufacturing",
            slug: "solutions/manufacturing",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Retail and eCommerce",
            slug: "solutions/retail",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Internal Comms",
            slug: "solutions/internal-communications",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Sports",
            slug: "solutions/sports",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Technology",
            slug: "solutions/technology",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
          {
            label: "Hospitality and Tourism",
            slug: "solutions/hospitality-tourism",
            class: "subnav-item-medium",
            hoverColor: "#ea5f34",
            altColor: "#f7cd54",
            useLink: true,
          },
        ],
      ],
    },
    resources: {
      label: "Resources",
      slug: "resources",
      link: false,
      useLink: false,
      class: "",
      columns: [
        [
          {
            label: "Resource Center",
            slug: "resources/resource-center",
            class: "subnav-item-medium",
            hoverColor: "#70e5e2",
            altColor: "#0b1e89",
            useLink: false,
          },
          {
            label: "Events",
            slug: "events",
            class: "subnav-item-medium",
            hoverColor: "#70e5e2",
            altColor: "#0b1e89",
            useLink: false,
          },
          // {
          //   label: 'Webinars',
          //   slug: 'webinars',
          //   class: 'subnav-item-medium',
          //   hoverColor: '#70e5e2',
          //   altColor: '#0b1e89',
          //   useLink: false,
          // },
          {
            label: "Blog",
            slug: "resources/blog",
            class: "subnav-item-medium",
            hoverColor: "#70e5e2",
            altColor: "#0b1e89",
            useLink: false,
          },
          // {
          //   label: 'Video Index',
          //   slug: 'resources/downloads/reports/2020-an-evolutionary-year-for-video',
          //   class: 'subnav-item-medium',
          //   hoverColor: '#70e5e2',
          //   altColor: '#0b1e89',
          //   useLink: false,
          // },
          {
            label: "Play TV",
            slug: "playtv",
            class: "subnav-item-medium",
            hoverColor: "#70e5e2",
            altColor: "#0b1e89",
            useLink: false,
          },
          {
            label: "Brightcove University",
            slug: "resources/university",
            class: "subnav-item-medium",
            hoverColor: "#70e5e2",
            altColor: "#0b1e89",
            useLink: false,
          },
          {
            label: "Brightcove Community",
            slug: " https://community.brightcove.com/s/",
            class: "subnav-item-medium",
            hoverColor: "#70e5e2",
            altColor: "#0b1e89",
            useLink: false,
          },
        ],
      ],
    },
    company: {
      label: "Company",
      slug: "company",
      link: false,
      useLink: false,
      class: "",
      columns: [
        [
          {
            label: "About Us",
            slug: "company/about",
            class: "subnav-item-medium",
            altColor: "#fff",
            useLink: false,
          },
          {
            label: "Careers",
            slug: "company/careers",
            class: "subnav-item-medium",
            altColor: "#fff",
            useLink: false,
          },
          {
            label: "Leadership",
            slug: "company/leadership",
            class: "subnav-item-medium",
            altColor: "#fff",
            useLink: false,
          },
          {
            label: "Newsroom",
            slug: "company/pressroom",
            class: "subnav-item-medium",
            altColor: "#fff",
            useLink: false,
          },
          {
            label: "Global Offices",
            slug: "contact-us",
            class: "subnav-item-medium",
            altColor: "#fff",
            useLink: false,
          },
          {
            label: "- ESG",
            slug: "company/about/esg",
            class: "subnav-item-medium",
            hoverColor: "#0b1e89",
            altColor: "#70e5e2",
            useLink: false,
          },
          {
            label: "Investor Relations",
            slug: "http://investor.brightcove.com/",
            class: "subnav-item-medium",
            link: "http://investor.brightcove.com/",
            altColor: "#fff",
            useLink: false,
          },
          // {
          //   label: 'Trust & Security',
          //   slug: 'trust-security',
          //   class: 'subnav-item-medium',
          //   altColor: '#fff',
          //   useLink: false,
          // },
          // {
          //   label: 'ESG',
          //   slug: 'company/esg',
          //   class: 'subnav-item-medium',
          //   altColor: '#fff',
          //   useLink: false,
          // },
        ],
      ],
    },
    // blog: {
    //   label: 'Blog',
    //   slug: 'blog',
    //   link: true,
    //   useLink: false,
    //   class: '',
    //   columns: [],
    // },
    // playtv: {
    //   label: 'Play TV',
    //   slug: 'https://playtv.brightcove.com/',
    //   link: true,
    //   useLink: false,
    //   class: '',
    //   columns: [],
    // },
    search: {
      label: "Search",
      slug: "searchBox",
      link: false,
      useLink: false,
      class: "icon-search icon hide-mobile",
      columns: [],
    },
    talk: {
      label: "Talk to Us",
      slug: "contact-sales",
      link: true,
      useLink: true,
      class: "button",
      columns: [],
    },
  },
  footer: {
    columns: [
      {
        products: {
          label: "Why Brightcove",
          slug: "why-brightcove",
          link: false,
          useLink: false,
          class: "",
          items: [
            {
              label: "Why Brightcove",
              slug: "why-brightcove",
              class: "",
              useLink: true,
            },
            {
              label: "- Monetize content",
              slug: "why-brightcove/monetize-content",
              class: "subnav-item-list sub-sub",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "- Engage people",
              slug: "why-brightcove/engage-people",
              class: "subnav-item-list sub-sub",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "- Sell your product",
              slug: "why-brightcove/sell-your-product",
              class: "subnav-item-list sub-sub",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "Customer Stories",
              slug: "customers",
              class: "",
              useLink: true,
            },
            {
              label: "Global Services",
              slug: "services",
              class: "",
              useLink: true,
            },
            {
              label: "Channel Partners",
              slug: "partners",
              class: "",
              useLink: true,
            },
            {
              label: "Brightcove Marketplace",
              slug: "https://marketplace.brightcove.com",
              class: "",
              link: "https://marketplace.brightcove.com",
              useLink: true,
            },
            // {
            //   label: 'Become a Partner',
            //   slug: 'become-a-partner',
            //   class: '',
            //   useLink: true,
            // },
          ],
        },
      },
      {
        videoEssentials: {
          label: "Products",
          slug: "video-essentials",
          link: false,
          useLink: false,
          class: "",
          items: [
            // {
            //   label: 'Video Essentials',
            //   slug: '',
            //   class: 'subnav-item-header',
            //   hoverColor: '',
            //   altColor: '',
            //   useLink: true,
            // },
            {
              label: "Video Communications Platform",
              slug: "products/online-video-platform",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "Audience-Insights",
              slug: "products/audience-insights",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "Simulive",
              slug: "products/simulive",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            // {
            //   label: 'Live',
            //   slug: 'products/live',
            //   class: 'subnav-item-list',
            //   hoverColor: '#0b1e89',
            //   altColor: '#70e5e2',
            //   useLink: true,
            // },
            {
              label: "Video Marketing Suite",
              slug: "products/video-marketing-suite",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "- Marketing Studio",
              slug: "products/marketing-studio",
              class: "subnav-item-list sub-sub",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "Enterprise Video Suite",
              slug: "products/enterprise-video-suite",
              class: "subnav-item-list nav-large",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "Virtual Events",
              slug: "products/virtual-events-experience",
              class: "subnav-item-list nav-large",
              hoverColor: "#70e5e2",
              altColor: "#0b1e89",
              useLink: true,
            },

            {
              label: "CorpTV",
              slug: "products/corptv",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "OTT",
              slug: "products/beacon-ott-platform",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            // {
            //   label: 'Player',
            //   slug: 'products/player',
            //   class: 'subnav-item-list',
            //   hoverColor: '#0b1e89',
            //   altColor: '#70e5e2',
            //   useLink: true,
            // },
            {
              label: "Video Encoding",
              slug: "products/zencoder",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "interactivity",
              slug: "products/interactivity",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
            {
              label: "Brightcove Marketplace",
              slug: "https://marketplace.brightcove.com/",
              link: "https://marketplace.brightcove.com/",
              class: "subnav-item-list",
              hoverColor: "#0b1e89",
              altColor: "#70e5e2",
              useLink: true,
            },
          ],
        },
      },
      // {
      //   videoSolutions: {
      //     label: 'Video Solutions',
      //     slug: 'video-solutions',
      //     link: false,
      //     class: '',
      //     items: [
      //       {
      //         label: 'Virtual Event Experience',
      //         slug: 'solutions/virtual-event',
      //         class: '',
      //       },
      //       {
      //         label: 'Enterprise Video Suite',
      //         slug: 'enterprise-video-suite',
      //         class: '',
      //       },
      //       {
      //         label: 'Video Marketing Suite',
      //         slug: 'solutions/marketers',
      //         class: '',
      //       },
      //       {
      //         label: 'Brightcove Continuum',
      //         slug: 'brightcove-continuum',
      //         class: '',
      //       },
      //     ],
      //   },
      // },
      // {
      //   videoAddOns: {
      //     label: 'Video Add-Ons',
      //     slug: 'video-add-ons',
      //     link: false,
      //     class: '',
      //     items: [
      //       {
      //         label: 'Brightcove Campaign',
      //         slug: 'brightcove-campaign',
      //         class: '',
      //       },
      //       {
      //         label: 'Brightcove Cloud Playout',
      //         slug: 'brightcove-cloud',
      //         class: '',
      //       },
      //       {
      //         label: 'Brightcove Engage',
      //         slug: 'brightcove-engage',
      //         class: '',
      //       },
      //       {
      //         label: 'Brightcove Gallery',
      //         slug: 'brightcove-gallery',
      //         class: '',
      //       },
      //       {
      //         label: 'Brightcove Social',
      //         slug: 'video-marketing-suite',
      //         class: '',
      //       },
      //       {
      //         label: 'Brightcove SAII',
      //         slug: 'saii',
      //         class: '',
      //       },
      //     ],
      //   },
      // },
      {
        solutions: {
          label: "Solutions",
          slug: "solutions",
          link: false,
          useLink: false,
          class: "",
          items: [
            {
              label: "Media and Broadcasters",
              slug: "solutions/media-broadcasters",
              class: "",
              useLink: true,
            },
            {
              label: "Healthcare",
              slug: "solutions/healthcare",
              class: "",
              useLink: true,
            },
            {
              label: "Financial-Services",
              slug: "solutions/financial-services",
              class: "",
              useLink: true,
            },
            {
              label: "Marketers",
              slug: "solutions/marketers",
              class: "",
              useLink: true,
            },
            {
              label: "Arts and Entertainment",
              slug: "solutions/arts-entertainment",
              class: "",
              useLink: true,
            },
            {
              label: "Oil-Gas",
              slug: "solutions/oil-gas-industry",
              class: "",
              useLink: true,
            },
            {
              label: "Publishers",
              slug: "solutions/publishers",
              class: "",
              useLink: true,
            },
            {
              label: "Manufacturing",
              slug: "solutions/manufacturing",
              class: "",
              useLink: true,
            },
            {
              label: "Retail and eCommerce",
              slug: "solutions/retail",
              class: "",
              useLink: true,
            },
            {
              label: "Internal Comms",
              slug: "solutions/internal-communications",
              class: "",
              useLink: true,
            },
            {
              label: "Sports",
              slug: "solutions/sports",
              class: "",
              useLink: true,
            },
            {
              label: "Technology",
              slug: "solutions/technology",
              class: "",
              useLink: true,
            },
            {
              label: "Hospitality and Tourism",
              slug: "solutions/hospitality-tourism",
              class: "",
              useLink: true,
            },
          ],
        },
      },
      {
        resources: {
          label: "Resources",
          slug: "resources",
          link: false,
          useLink: false,
          class: "",
          items: [
            {
              label: "Resource Center",
              slug: "resources/resource-center",
              class: "",
              useLink: false,
            },
            {
              label: "Events",
              slug: "events",
              class: "",
              useLink: false,
            },
            // {
            //   label: 'Webinars',
            //   slug: 'webinars',
            //   class: '',
            //   useLink: false,
            // },
            {
              label: "Brightcove University",
              slug: "resources/university",
              class: "",
              useLink: false,
            },
            {
              label: "Brightcove Community",
              slug: "Brightcove Community",
              class: "",
              link: "https://community.brightcove.com/s/",
              useLink: false,
            },
            // {
            //   label: 'Video Index',
            //   slug: 'resources/downloads/reports/2020-an-evolutionary-year-for-video',
            //   class: '',
            //   useLink: false,
            // },
          ],
        },
        blog: {
          label: "Blog",
          slug: "resources/blog",
          link: true,
          useLink: false,
          class: "",
          items: [],
        },
        playtv: {
          label: "Play TV",
          slug: "playtv",
          link: true,
          useLink: false,
          class: "",
          items: [],
        },
      },
      {
        company: {
          label: "Company",
          slug: "company",
          link: false,
          useLink: false,
          class: "",
          items: [
            {
              label: "About Us",
              slug: "company/about",
              class: "",
              useLink: false,
            },
            {
              label: "Careers",
              slug: "company/careers",
              class: "",
              useLink: false,
            },
            {
              label: "Leadership",
              slug: "company/leadership",
              class: "",
              useLink: false,
            },
            {
              label: "Newsroom",
              slug: "company/pressroom",
              class: "",
              useLink: false,
            },
            {
              label: "Global Offices",
              slug: "contact-us",
              class: "",
              useLink: false,
            },
            {
              label: "ESG",
              slug: "company/about/esg",
              class: "subnav-item-medium",
              altColor: "#fff",
              useLink: false,
            },
            {
              label: "Investor Relations",
              slug: "investor",
              class: "",
              link: "http://investor.brightcove.com/",
              useLink: false,
            },
            {
              label: "Visiting Policy",
              slug: "company/visiting-brightcove",
              class: "",
              useLink: false,
            },
            // {
            //   label: 'Trust & Security',
            //   slug: 'trust-security',
            //   class: 'subnav-item-medium',
            //   altColor: '#fff',
            //   useLink: false,
            // },
          ],
        },
      },
      {
        contact: {
          label: "Contact Us",
          slug: "contact-sales",
          link: true,
          useLink: false,
          class: "",
          items: [],
        },
        signin: {
          label: "Sign In",
          slug: "signin",
          link: false,
          useLink: false,
          class: "",
          items: [
            {
              label: "Video Cloud",
              slug: "supportportal-login",
              class: "",
              link: "https://signin.brightcove.com/",
              useLink: false,
            },
            {
              label: "Zencoder",
              slug: "support-zencoder",
              class: "",
              link: "https://app.zencoder.com/login",
              useLink: false,
            },
          ],
        },
        support: {
          label: "Support",
          slug: "support",
          link: false,
          useLink: false,
          class: "",
          items: [
            {
              label: "Contact Support",
              slug: "supportportal",
              class: "",
              link: "https://help.brightcove.com/en/contact/create",
              useLink: false,
            },
            {
              label: "Product Documentation",
              slug: "supportpage",
              class: "",
              link: "https://support.brightcove.com/",
              useLink: false,
            },
          ],
        },
      },
    ],
  },
  social: {
    LinkedIn: {
      icon: (
        <FaLinkedinIn
          color={"#fff"}
          style={{ width: "16px", height: "16px" }}
        />
      ),
      url: "https://www.linkedin.com/company/brightcove",
    },
    Twitter: {
      icon: (
        <FaXTwitter color={"#fff"} style={{ width: "16px", height: "16px" }} />
      ),
      url: "https://twitter.com/brightcove",
    },
    Facebook: {
      icon: (
        <FaFacebook color={"#fff"} style={{ width: "16px", height: "16px" }} />
      ),
      url: "https://www.facebook.com/Brightcove",
    },
    Instagram: {
      icon: (
        <FaInstagram color={"#fff"} style={{ width: "16px", height: "16px" }} />
      ),
      url: "https://www.instagram.com/Brightcove",
    },
    RSS: {
      icon: (
        <FaRssSquare color={"#fff"} style={{ width: "16px", height: "16px" }} />
      ),
      url: "https://www.brightcove.com/en/blog/feed/all",
    },
  },
  legal: {
    Privacy: "legal/privacy",
    "Terms & Conditions": "terms-and-conditions",
  },
  copy: `© ${year.getFullYear()} Brightcove Inc. All rights reserved.`,
};

export default navItems;
