import styled from "styled-components";

export const FooterBlock = styled("footer")`
  content-visibility: auto;
  contain-intrinsic-size: 1280px 600px;
  background-color: #000;
  color: #fff;
  border-bottom: 4px solid #000000;
  padding: 100px 0 60px 0;
  overflow: hidden;
  a {
    text-transform: none;
    padding: 0;
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 2px;
    color: #fff;
    border: 0;
    display: inline-block;
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: black;
      transition: width 300ms ease;
      margin-top: 4px;
    }

    &:hover {
      text-decoration: none;
      &:after {
        width: 100%;
      }
    }
  }

  .footer-logo {
    text-align: center;
    padding: 0 0 60px;

    a {
      font-size: 2rem;

      &:after {
        display: none;
      }
    }
  }

  .column-social {
    display: flex;
    flex-direction: column;
    align-self: self-start;
    padding: 20px 0px;
    a {
      font-size: 1px;
      color: #fff;
      display: block;
      width: 18px;
      height: 40px;
      span {
        background-size: 18px 18px;
      }
    }
  }

  .footer-column {
    .bold-nav-item {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.9rem;
      margin: 18px 0;
      font-family: "articulat-cf", sans-serif;

      a {
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: -8px;
      }
    }

    > div:first-child {
      p {
        margin-top: 0;
      }
    }
    .bold-link-gray {
      margin-bottom: 15px;
      color: #fff;
      > a {
        color: #fff;
        margin-top: 10px;
      }
    }

    .sub-bold {
      margin: 0;
      padding-top: 20px;
    }
  }

  .copy-text {
    font-size: 0.8rem;
    text-align: center;
    padding: 40px 0 20px;
    a {
      font-size: 0.8rem;
      margin: 0 10px;
    }
  }

  .footer-tablet {
    display: flex;
    justify-content: center;
    gap: 100px;
    @media screen and (max-width: 990px) {
      gap: 50px;
    }
    &-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &-row {
    }
    &-social {
      display: flex;
      width: 100%;
      margin-top: 30px;
      align-items: center;
      justify-content: center;
      gap: 10px;
      a {
        font-size: 1px;
        color: #fff;
        display: block;
        width: 18px;
        height: 40px;
        span {
          background-size: 18px 18px;
        }
      }
    }
    .bold-nav-item {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.9rem;
      margin: 18px 0;
      font-family: "articulat-cf", sans-serif;

      a {
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-bottom: -8px;
      }
    }

    .bold-link-gray {
      margin-bottom: 15px;
      color: #fff;
      > a {
        color: #fff;
        margin-top: 10px;
      }
    }

    .sub-bold {
      margin: 0;
      padding-top: 20px;
    }
  }
`;

export const FooterMobile = styled.div`
  margin: 0 30px;
  margin-top: -50px;
  ul {
    list-style-type: none;
    .footer-mobile-accordion-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      button {
        cursor: pointer;
        width: 100%;
        text-transform: uppercase;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding: 10px 0;
        background-color: transparent;
        color: white;
        border: none;
        font-family: "articulat-cf", sans-serif;
        font-size: 16px;
        font-weight: 700;
        .icon {
          margin-top: -15px;
          color: white;
        }
      }
    }
  }
`;

export const FooterMobileTopLevelAccordion = styled("div")<{
  $accordionHeight: number;
}>`
  padding-left: 60px;
  ul {
    margin-top: 0;
  }
  max-height: ${({ $accordionHeight }) => `${$accordionHeight}px`};
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  /* Phone:  max-width: 768px */
  @media only screen and (max-width: 768px) {
    padding-left: 20px;
  }
  &[aria-expanded="true"] {
    max-height: ${({ $accordionHeight }) => `${$accordionHeight}px`};
    transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  &[aria-expanded="false"] {
    transition: max-height 0.5s ease-in-out;
  }

  li {
    margin-bottom: 0;
    a {
      font-size: 16px;
    }
  }
`;
