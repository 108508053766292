import styled from "styled-components";

export const Logo = styled.div`
  display: inline-block;
  width: 160px;
  line-height: 0;
  span {
    font-size: 1px;
    color: rgba(0, 0, 0, 0);
    display: inline-block;
    width: 0;
    overflow: hidden;
  }
`;
