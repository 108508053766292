import React from "react";
import styled from "styled-components";

const RowWrapper = styled("div")<{
  $align?: string;
  $justify?: string;
  $gutter?: string;
}>`
  display: flex;
  flex-flow: row wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: ${({ $align }) => $align || "normal"};
  justify-content: ${({ $justify }) => $justify || "flex-start"};
  padding: ${({ $gutter }) => `0px ${$gutter || "0"}px`};
`;

interface RowStyleProps {
  id?: string;
  gutter?: string;
  className?: string;
  style?: React.CSSProperties;
  align?: string;
  justify?: string;
  children?: React.ReactNode;
}

const RowStyle: React.FC<RowStyleProps> = ({
  id,
  gutter,
  className,
  style,
  align,
  justify,
  children,
}) => {
  return (
    <RowWrapper
      id={id}
      $align={align}
      $justify={justify}
      style={style}
      className={className}
      $gutter={gutter}
    >
      {children}
    </RowWrapper>
  );
};

export default RowStyle;
