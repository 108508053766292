import Cookies from "js-cookie";

export const sfmcCookie = () => {
  // String that represents the query for the external id
  const queryLookup = "mapid=";
  // String that represents the key for our own cookie storing the external id
  const sfmcIdCookieKey = "_sfmc_id_";
  // If DOM has loaded and window/window.location are present
  if (window && window.location) {
    // Get cookies back in an object,
    // Turn key-value pairs into tuples
    // and object into array
    // Filter out cookies with a key including '_evga_'
    let cookieList = Object.keys(Cookies.get())
      .map(key => [key, Cookies.get()[key]])
      .filter(cookie => cookie[0].includes("_evga_"));
    // If there is no evergage cookie, set this value to '_evga_1924' by default
    // If there is only one cookie with the evga prefix, set the value to this key
    // If there are multiple cookies with the evga prefix, take the one that includes
    // 'uuid' in the value
    let evergageCookieKey =
      cookieList.length === 0
        ? "_evga_1924"
        : cookieList.length === 1
          ? cookieList[0][0]
          : cookieList.filter(cookie => cookie[1].includes("uuid"))[0][0];
    // If URL contains the query for the external id
    if (window.location.href.indexOf(queryLookup) !== -1) {
      // Split the URL between what's before the query for the external id and what is after
      let splitPath = window.location.href.split(queryLookup);
      // If the second part of the splitPath variable contains more than one query,
      // split the string on '&' and take the first indexed string, otherwise,
      // just take the second indexed string of splitPath
      let mapId =
        splitPath[1].indexOf("&") !== -1
          ? splitPath[1].split("&")[0]
          : splitPath[1];
      // Set a cookie and store the external id for 365 days
      Cookies.set(sfmcIdCookieKey, mapId, { expires: 365 });
      // If URL does not contain the query for the external id
    } else {
      // If a cookie has not yet been set for this user and the Evergage cookie exists
      if (
        !Cookies.get(sfmcIdCookieKey) &&
        Cookies.get(evergageCookieKey) !== undefined
      ) {
        // Obtain the value of the Evergage cookie
        let evgaCookie = Cookies.get(evergageCookieKey);
        // Set the external id cookie with the value from the Evergage cookie
        Cookies.set(
          sfmcIdCookieKey,
          decodeURI(evgaCookie || "")
            .split(":")[1]
            .split("}")[0]
            .replace(/['"]+/g, ""),
          {
            expires: 365,
          }
        );
      }
    }
  }
};
