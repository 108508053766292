"use client";
import React, { useState } from "react";
import MobileNavbarStyles from "./MobileNavbarStyles";
import SideBar from "./SideBar/SideBar";
import Logo from "@/src/components/Global_Components/Logo/Logo";
import { RxHamburgerMenu } from "react-icons/rx";

const MobileNavbar = ({ lang, translations, navData }) => {
  const [activeSideBar, setActiveSideBar] = useState(false);
  return (
    <MobileNavbarStyles>
      <Logo lang={lang} />
      {!activeSideBar && (
        <div onClick={() => setActiveSideBar(true)} className="hamburger">
          <RxHamburgerMenu className="hamburger" />
        </div>
      )}
      {activeSideBar && (
        <SideBar
          navData={navData}
          setActiveSideBar={setActiveSideBar}
          lang={lang}
          translations={translations}
        />
      )}
    </MobileNavbarStyles>
  );
};

export default MobileNavbar;
