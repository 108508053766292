import React from "react";
import * as Styles from "./LogoStyles";
import Link from "next/link";

interface LogoProps {
  color?: string;
  peelable?: boolean;
  className?: string;
  lang?: string;
}

const Logo = ({ color, peelable, className, lang }: LogoProps) => {
  return (
    <Styles.Logo className={className}>
      <Link prefetch href={`/${lang}/`}>
        <span>Brightcove</span>
        {peelable ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
            <defs></defs>
            <g>
              <g>
                <path
                  fill={color}
                  d="M31.12,7.58a11.26,11.26,0,0,0-6-1.4H6.16v27.2L0,27.22V0H18.92C22,0,24.24.54,26,2.34L31.2,7.5ZM32.8,9.1l5.4,5.4a6.51,6.51,0,0,1,1.72,4.8v.64c0,3-.92,4.36-3.84,4.88v.36l2.56,2.56a5.21,5.21,0,0,1,1.36,4v1.32c0,4.8-3.2,7-8.68,7H12.8L6.64,33.86H25.16c5.48,0,8.68-2.16,8.68-7V25.58c0-3.72-1.68-5.36-6.52-5.52V19.3c4.84-.16,6.44-1.8,6.44-5.52v-.64a7.14,7.14,0,0,0-1-4ZM15.44,11.82h6.08c2.32,0,3,.6,3,2.52v.2c0,1.92-.72,2.52-3,2.52H15.44Zm0,16.4V22.7h6.08c2.32,0,3,.6,3,2.52v.48c0,1.92-.72,2.52-3,2.52Z"
                />
                <path
                  fill={color}
                  d="M26.54.34V0h1.7V.34H27.6V2h-.4V.34ZM29.14,0l.5,1.42L30.16,0h.56V2h-.36V.4L29.8,2h-.32L28.92.4V2h-.36V0Z"
                />
              </g>
            </g>
          </svg>
        ) : (
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            fill={color}
            viewBox="0 0 784.6 179.6"
            style={{
              // @ts-ignore
              enableBackground: "new 0 0 784.6 179.6",
            }}
            xmlSpace="preserve"
          >
            <g>
              <defs>
                <path
                  id="SVGID_1_"
                  d="M173.5,51.8h26v126h-26V51.8z M158.1,84.9v4.9c0,16-5.6,23.2-20.2,24.7v2.3c14.6,1.5,20.2,8.7,20.2,24.7
			v36.3h-26v-37.2c0-10.1-3-13.1-10.8-13.1h-8.7v50.3h-26v-126h39.9C149.6,51.8,158.1,61.6,158.1,84.9 M132.1,86.3
			c0-10.1-3-13.1-10.8-13.1h-8.7v32.9h7.4c8.7,0,12-3.1,12-13.5L132.1,86.3z M72.4,138.4v6.3c0,23.3-8.4,33.1-31.6,33.1H0v-126h40.2
			c23.1,0,31.6,9.8,31.6,33.1v1.8c0,16-5.6,23.2-20.2,24.7v2.3C66.8,115.2,72.4,122.3,72.4,138.4 M26,103.1h8c8.7,0,12-3.2,12-13.5
			v-3.2c0-10.1-3-13.1-10.8-13.1H26V103.1z M46.4,137.6c0-10.1-3-13.1-10.8-13.1H26v31.9h9.7c7.8,0,10.8-3,10.8-13.1V137.6z
			 M251,49.9c-23.5,0-35.5,13.2-35.5,37.8v58c0,22.9,9.3,34,29.7,34c17.1,0,24-7.3,24.8-25.1h1.8l3.4,23.2h11.4v-69.7h-35.3v19.1
			h10.5v18.6c0,8.8-3.4,12.6-10.2,12.6s-10.2-3.8-10.2-12.6V83.7c0-8.8,3.4-12.6,9.7-12.6s9.6,3.8,9.6,12.6V97h25.7V86.4
			C286.6,62.4,274.8,49.9,251,49.9 M650.5,155.9h-2.4l-9.4-104.2h-26.9l14.5,126h43.1l14-126h-24L650.5,155.9z M603.3,87.7v54.2
			c0,24.6-12.4,37.8-35.9,37.8s-35.9-13.2-35.9-37.8V87.7c0-24.6,12.4-37.8,35.9-37.8S603.3,63.1,603.3,87.7 M577.3,83.6
			c0-8.8-3.6-12.6-9.9-12.6s-9.9,3.8-9.9,12.6v62.2c0,8.8,3.6,12.6,9.9,12.6c6.3,0,9.9-3.8,9.9-12.6V83.6z M753.1,73.6V51.8h-58.9
			v126H753v-21.8h-32.8v-31.2h30.6v-21.1h-30.6v-30L753.1,73.6z M382.1,73.6h18.2v104.2h26V73.6h18.2V51.8h-62.4L382.1,73.6z
			 M485,49.9c-22.8,0-35,13.2-35,37.8v54.2c0,24.6,12.3,37.8,35,37.8c23.1,0,35-12.5,35-36.5v-15.4h-25.7v18.1
			c0,8.8-3.4,12.6-9.1,12.6s-9.1-3.8-9.1-12.6V83.7c0-8.8,3.4-12.6,9.1-12.6s9.1,3.8,9.1,12.6v16.9h25.7V86.4
			C520.1,62.4,508.2,49.9,485,49.9 M347.4,103.7h-18.1V51.8h-26v125.9h26v-52.9h18.2v52.9h26V51.8h-26L347.4,103.7z M778.3,59.4
			v-0.4c0-2-1.5-3.4-4.2-3.4h-4.6v10.8h2.2v-3.6h2.4l1.8,3.6h2.4l-2.3-4.3C777.4,61.9,778.3,60.7,778.3,59.4 M771.7,57.6h2.4
			c1.4,0,2,0.5,2,1.5v0.1c0,1-0.6,1.5-2,1.5h-2.4V57.6z M773.7,49.9c-6.2-0.1-11.2,4.8-11.3,11s4.8,11.2,11,11.3
			c6.2,0.1,11.2-4.8,11.3-11c0-0.1,0-0.1,0-0.2c0.2-6-4.5-11-10.5-11.2H773.7 M773.7,70.7c-5.3,0.2-9.8-4-9.9-9.4
			c-0.2-5.3,4-9.8,9.4-9.9s9.8,4,9.9,9.4c0,0.1,0,0.1,0,0.2v0.1c0.3,5-3.6,9.3-8.6,9.6L773.7,70.7 M208.3,23.8v-2.3
			c-13.7-1.4-20-7.8-20.8-21.4h-2.4c-0.9,13.7-7.2,20-20.9,21.4v2.3c14.2,1.4,20,7.7,21,21.1h2.1C188.3,31.4,194.2,25.2,208.3,23.8z
			"
                />
              </defs>
              <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
              <clipPath id="SVGID_00000129184006470371718700000012557716577008318641_">
                <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
              </clipPath>
            </g>
          </svg>
        )}
      </Link>
    </Styles.Logo>
  );
};

export default Logo;
