import React from "react";

const ArrowRight = () => {
  return (
    <svg fill="none" viewBox="0 0 16 16" width="16" height="16">
      <path
        fill="#000"
        fillRule="evenodd"
        d="M5.29316 2.9087c-.39088-.39089-.39088-1.02464 0-1.41552.39089-.39089 1.02464-.39089 1.41553 0l6.29971 6.29973c.3905.39052.3905 1.02368 0 1.41421L6.70869 15.5068c-.39089.3909-1.02464.3909-1.41553 0-.39088-.3908-.39088-1.0246 0-1.4155l5.59134-5.59129L5.29316 2.9087Z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ArrowRight;
