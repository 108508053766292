import styled from "styled-components";

const BlockColumnsStyle = styled.div`
  .wrapper-column {
    display: flex;
    text-align: left;
  }
  /* Header Column */
  .header-column {
    padding: 10px 0px;
    position: absolute;
    top: -35px;
    margin-left: -10px;
  }

  .col-block-column {
    align-self: center;
  }
  /* Color-bar */
  .color-bar {
    position: relative;
    left: 0;
    width: 2px;
    background: linear-gradient(to top, #4ea9d1, #aa6c7e, #f76531, #efd900);
    height: auto;
    margin-right: 10px;
  }
`;

export default BlockColumnsStyle;
