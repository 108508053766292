"use client";
import React from "react";

interface LightboxContextType {
  lightBoxData: {
    open: boolean;
    type: string;
    content: any;
    playerId?: string;
  };
  setLightBoxData: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      content: string;
      playerId?: string;
    }>
  >;
}

const LightboxContext = React.createContext<LightboxContextType | undefined>(
  undefined
);

type Props = {
  children: React.ReactNode;
};

export const LightboxContextProvider = ({ children }: Props) => {
  const [lightBoxData, setLightBoxData] = React.useState({
    open: false,
    type: "",
    content: "",
    playerId: "",
  });

  return (
    <LightboxContext.Provider value={{ lightBoxData, setLightBoxData }}>
      {children}
    </LightboxContext.Provider>
  );
};

export const useLightboxData = () => React.useContext(LightboxContext);
