import styled from "styled-components";

const ImageBlockStyle = styled.div`
  align-items: center;
  display: grid;
  .Text-Image-Block {
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }

  img {
    border-radius: 8px;
    background-color: transparent;
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @-webkit-keyframes fade-in-top {
      0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes fade-in-top {
      0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    .Text-Image-Block {
      font-size: 14px;
    }
  }
`;

export default ImageBlockStyle;
