export const BREAK_POINTS = {
  DESKTOP_XLARGE: "min-width: 1820px",
  DESKTOP_LARGE: "min-width: 1600px",
  DESKTOP_MED: "min-width: 1441px",
  DESKTOP_MIN: "max-width: 1440px",
  TABLET_LANDSCAPE_MAX: "max-width: 1279px",
  TABLET_PORTRAIT_MAX: "max-width: 1000px",
  MOBILE_MAX: "max-width: 739px",
  MOBILE: "max-width: 768px",
  TABLET: "min-width: 768px",
  DESKTOP: "min-width: 1024px",
};
