import React from "react";

const SmallLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <defs></defs>
      <g>
        <g>
          <path
            fill={"#000"}
            d="M31.12,7.58a11.26,11.26,0,0,0-6-1.4H6.16v27.2L0,27.22V0H18.92C22,0,24.24.54,26,2.34L31.2,7.5ZM32.8,9.1l5.4,5.4a6.51,6.51,0,0,1,1.72,4.8v.64c0,3-.92,4.36-3.84,4.88v.36l2.56,2.56a5.21,5.21,0,0,1,1.36,4v1.32c0,4.8-3.2,7-8.68,7H12.8L6.64,33.86H25.16c5.48,0,8.68-2.16,8.68-7V25.58c0-3.72-1.68-5.36-6.52-5.52V19.3c4.84-.16,6.44-1.8,6.44-5.52v-.64a7.14,7.14,0,0,0-1-4ZM15.44,11.82h6.08c2.32,0,3,.6,3,2.52v.2c0,1.92-.72,2.52-3,2.52H15.44Zm0,16.4V22.7h6.08c2.32,0,3,.6,3,2.52v.48c0,1.92-.72,2.52-3,2.52Z"
          />
          <path
            fill={"#000"}
            d="M26.54.34V0h1.7V.34H27.6V2h-.4V.34ZM29.14,0l.5,1.42L30.16,0h.56V2h-.36V.4L29.8,2h-.32L28.92.4V2h-.36V0Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SmallLogo;
