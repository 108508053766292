import styled from "styled-components";

const SignInStyles = styled.div`
  width: 100%;
  .toggle {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    border-bottom: 1px solid #3f4b66;
    color: #000;
    margin-bottom: 0;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #000;
      color: #fff;
      path {
        fill: #fff;
      }
    }
  }
  .detail-container {
    background: white;
    color: #000;
    position: absolute;
    height: 780px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    top: 0;
    right: 0px;
    border-radius: 8px;
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    @-webkit-keyframes slide-in-right {
      0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
    }
    @keyframes slide-in-right {
      0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
    }
    /* Back */
    .wrapper-first-back {
      padding: 20px;
      border-bottom: 1px solid #3f4b66;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .back-button {
        width: 50px;
        cursor: pointer;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
      .icon-close {
        width: 45px;
        font-size: 22px;
        cursor: pointer;
        &:hover {
          transition: all 0.2s ease-in-out;
          transform: scale(1.01);
        }
      }
    }
    /* Tab List  */
    li {
      padding: 0px;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      border-bottom: 1px solid #3f4b66;
      color: #000;
      margin-bottom: 0;
      background-color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #000;
        a {
          color: #fff;
        }
      }
    }
    .signin {
      a {
        padding: 20px;
        width: 100%;
        color: #000;
      }
    }
    @media only screen and (max-width: 598px) {
      max-width: 100%;
      margin: auto;
    }
  }
`;

export default SignInStyles;
