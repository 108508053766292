import React, { useState } from "react";
import SignInStyles from "./SignInStyles";

import ArrowRight from "../../image/ArrowRight";

import ArrowLeft from "../../image/ArrowLeft";

import { MdClose } from "react-icons/md";

const SignIn = ({ setActiveSideBar, translations }) => {
  const [activeDetails, setActiveDetails] = useState(false);

  return (
    <SignInStyles>
      <div className="toggle" onClick={() => setActiveDetails(true)}>
        {translations["Sign In"]} <ArrowRight />
      </div>
      {/* details */}
      {activeDetails && (
        <div className="detail-container">
          <div className="wrapper-first-back">
            <div
              className="back-button"
              onClick={() => setActiveDetails(false)}
            >
              <ArrowLeft /> <p>Back</p>
            </div>
            <div onClick={() => setActiveSideBar(false)}>
              <MdClose className="icon-close" />
            </div>
          </div>
          <div>
            <ul>
              <li className="signin">
                <a href="https://signin.brightcove.com/" target="_blank">
                  Video Cloud
                </a>
              </li>
              <li className="signin">
                <a href="https://app.zencoder.com/login" target="_blank">
                  Zencoder
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </SignInStyles>
  );
};

export default SignIn;
