import React from "react";

import Link from "next/link";

import SubNavItemsStyle from "./SubNavItemsStyles";

const SubNavItems = props => {
  const {
    subNavItems,
    columnNumber,
    getImageSubNavItems,
    ImageDefaultTab,
    lang,
  } = props;
  // Display SubNavItems based on the columnNumber
  const DisplaySubNavItems = () => {
    return subNavItems
      ?.filter(n => n.fields.columnNumber == columnNumber)
      .map((item, index) => {
        return (
          // Uncoment this div bellow and remove the simple div follow this Div IF you want on hover link image change based on the image tab
          // <div onMouseLeave={() => getImageSubNavItems(ImageDefaultTab)}> replace that div if hover link change default image
          <div key={index}>{displayLink(item.fields)}</div>
        );
      });
  };

  // Function Check if internal or external Link
  const displayLink = item => {
    if (item.slug?.includes("https")) {
      return (
        // Uncoment IF you want on hover link image change based on the image tab
        // onMouseOver={() => item.itemImage && getImageSubNavItems(item.itemImage)}
        <a className="wrapper-link" href={item.slug} target="_blank">
          {item.label}
          {item.isNew && (
            <sup style={{ marginLeft: "4px", color: "#F76531" }}>NEW</sup>
          )}
        </a>
      );
    } else {
      return (
        <Link
          prefetch
          // Uncoment IF you want on hover link image change based on the image tab
          // onMouseOver={() => item.itemImage && getImageSubNavItems(item.itemImage)}
          className="wrapper-link"
          href={`/${lang}/${item.slug}/`}
        >
          {item.label}
          {item.isNew && (
            <sup style={{ marginLeft: "4px", color: "#F76531" }}>NEW</sup>
          )}
        </Link>
      );
    }
  };

  return (
    <SubNavItemsStyle lang={lang}>
      <div>{DisplaySubNavItems()}</div>
    </SubNavItemsStyle>
  );
};

export default SubNavItems;
