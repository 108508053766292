import styled from "styled-components";

export const BannerStyles = styled("div")<{ $lang: string }>`
  background-color: #f76531;
  padding: 15px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: ${({ $lang }) => ($lang === "ja" ? "18px" : "20px")}; // change for japanese
  
  font-weight: 500;
  text-align: center;

  @media only screen and (max-width: 1279px) {
    padding: 15px 30px;
    font-size: ${({ $lang }) => ($lang === "ja" ? "14px" : "16px")}; // change for japanese
  }

  a {
    color: #0000ee;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;
