import React, { useState } from "react";

import RowStyle from "@/src/components/Global_Components/GridSystem/RowStyle";
import ColStyle from "@/src/components/Global_Components/GridSystem/ColStyle";

import SubNavItems from "../SubNavItems/SubNavItems";

import BlockColumnsStyle from "./BlockColumnsStyles";

import Remark from "@/src/components/Global_Components/Remark";

import ImageBlock from "../ImageBlock/ImageBlock";
import Link from "next/link";

const BlockColumn = ({ infoTab, lang }) => {
  const numberOfColumns = infoTab.numberOfColumns; // Number of Columns for that cureent Tab
  const headerTitles = [
    infoTab.headerColumnOne,
    infoTab.headerColumnTwo,
    infoTab.headerColumnThree,
  ]; // All Titles per Tabs
  const ImageDefaultTab = infoTab.itemImage; // Image default from Tab
  const itemDescription = infoTab.itemDescription; // Description Default from Tab

  const [imageBlock, setImageBlock] = useState(infoTab.itemImage);

  // Function to get Image per subItem and pass to ImageBlock
  const getImageSubNavItems = image => {
    setImageBlock(image);
  };

  // Object number of column based on the numberOfColumns for the tab
  const columnToGrid = {
    1: 6,
    2: 4,
    3: 3,
  };

  // Function Check if internal or external Link
  const displayImageLink = () => {
    if (infoTab.imageLink?.includes("https")) {
      return (
        <a href={infoTab.imageLink} target="_blank">
          {ImageDefaultTab && (
            <ImageBlock
              itemDefaultDescription={itemDescription}
              imageBlock={imageBlock}
            />
          )}
        </a>
      );
    } else {
      return (
        <Link prefetch href={infoTab.imageLink.includes("http") ? infoTab.imageLink : `/${lang?.slice(0, 2)}/${infoTab.imageLink}`}>
          {ImageDefaultTab && (
            <ImageBlock
              itemDefaultDescription={itemDescription}
              imageBlock={imageBlock}
            />
          )}
        </Link>
      );
    }
  };

  // Function to display number of column.
  const displayNumberofColumns = () => {
    const block = [...Array(numberOfColumns)].map((val, index) => {
      return (
        <React.Fragment key={index}>
          <ColStyle
            style={{ alignSelf: "center" }}
            lg={columnToGrid[numberOfColumns || 0]}
            gutter="15"
          >
            {/* Column */}
            <div key={index} className="wrapper-column">
              <div className="color-bar"></div>
              {/* Herder Column */}
              {headerTitles
                .filter((n, titleIndex) => titleIndex === index && n !== null)
                .map((title, indexHeader) => {
                  return (
                    <Remark
                      key={indexHeader}
                      className="header-column"
                      string={title}
                    />
                  );
                })}
              {/* SubNavItems */}
              <SubNavItems
                subNavItems={infoTab.subNavItems}
                columnNumber={index + 1}
                getImageSubNavItems={getImageSubNavItems}
                ImageDefaultTab={ImageDefaultTab}
                lang={lang}
              />
            </div>
          </ColStyle>
        </React.Fragment>
      );
    });
    return block;
  };

  return (
    <BlockColumnsStyle>
      <RowStyle>
        {displayNumberofColumns()}
        {/* Default Image   */}
        <ColStyle
          style={{ alignSelf: "center" }}
          lg={columnToGrid[numberOfColumns || 0]}
          gutter={"0"}
        >
          {displayImageLink()}
        </ColStyle>
      </RowStyle>
    </BlockColumnsStyle>
  );
};

export default BlockColumn;
